import { useEffect, useState } from 'react';

export function useDarkMode(): [boolean, (dark: boolean) => void] {
    const isBrowser = () => typeof window !== "undefined";
    let initValue = false;
    if (isBrowser()) {
        initValue = window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    const [darkMode, setDarkMode] = useState(initValue);

    function addClassToHTML(darkMode: boolean) {
        const target = document.documentElement.classList;
        let codeThemeEl = document.querySelector('#code-theme') as HTMLLinkElement | undefined;

        if (!codeThemeEl) {
            const element = document.createElement("link");
            element.id = 'code-theme';
            element.rel = 'stylesheet';
            element.media = 'screen';
            element.href = '/prism-darcula.min.css';
            document.head.appendChild(element);
            codeThemeEl = element;
        }

        const darkClassName = 'dark';
        if (darkMode) {
            target.add(darkClassName);
            codeThemeEl.setAttribute('href', '/prism-darcula.min.css');
        } else {
            target.remove(darkClassName);
            codeThemeEl.setAttribute('href', '/prism-ghcolors.min.css');
        }
    }

    useEffect(() => {
        function handleChange(e: MediaQueryListEvent) {
            setDarkMode(e.matches);
            addClassToHTML(e.matches);
        }

        addClassToHTML(darkMode);
        const query = window.matchMedia('(prefers-color-scheme: dark)');
        query.addEventListener('change', handleChange);

        // Add transition CSS
        const element = document.createElement("link");
        element.href = '/transition.css'
        element.rel = 'stylesheet';
        element.media = 'screen';
        document.head.appendChild(element);

        return () => {
            query.removeEventListener('change', handleChange);
        };
    }, []);

    return [darkMode, (v) => {
        setDarkMode(v);
        addClassToHTML(v);
    }];
}
