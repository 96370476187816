import React from 'react';
import './src/styles/global.css';
import { useDarkMode } from './src/hooks/useDarkMode';

export const ThemeContext = React.createContext({ darkMode: false, setDarkMode(dark: boolean) { } });

export const Wrapper = (props: any) => {
  const [darkMode, setDarkMode] = useDarkMode();
  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <div className="wrapper">{props.children}</div>
    </ThemeContext.Provider>
  );
};
